import React, { Component } from 'react'
import './index.css'

export default class Notice extends Component {
	state = {
		isShowNav: false,
		navList: [
			{ name: '首页', id: 0 },
			{ name: '业务', id: 1 },
			{ name: '关于我们', id: 4 }
		],
		navActive: 3
	}
	showNavList = () => {
		this.setState({ isShowNav: !this.state.isShowNav })
	}
	sleectNav = index => {
		this.setState({ navActive: index })
		switch (index) {
			case 0:
				//语句
				this.props.history.push(`/`)
				break
			case 1:
				this.props.history.push(`/business`)
				//语句
				break
			case 2:
				this.props.history.push(`/user`)
				//语句
				break
			default:
				//语句
				this.props.history.push(`/`)
		}
	}
	render() {
		return (
			<div className='m_box'>
				<div className='m_head'>
					<img
						className='m_logo'
						src={require('../../assets/images/m_icon_logo.png')}
						alt=''
					/>
					<img
						className='m_nav_icon'
						src={require('../../assets/images/icon_gengduo.png')}
						onClick={() => this.showNavList()}
						alt=''
					/>
				</div>
				{/* 下拉菜单 */}
				{this.state.isShowNav ? (
					<div className='m_nav'>
						{this.state.navList.map((item, index) => {
							return (
								<div
									className={
										this.state.navActive === item.id
											? 'm_nav_item_active'
											: 'm_nav_item'
									}
									key={index}
									onClick={() => this.sleectNav(index)}
								>
									{item.name}
								</div>
							)
						})}
					</div>
				) : null}
				<div className='content'>
					<div className='content_box'>
						<div>
							<div className='bold_text'>一、关于我们</div>
							<div className='bold_text'>（一）企业简介</div>
							<div>
								天津畅游天下科技有限公司，成立于2020年，是一家从事互联网和相关服务为主的企业。主要经营的业务有技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；互联网安全服务；互联网数据服务等。
							</div>

							<br />
						</div>
					</div>
					<div className='footer'>
						<div className='about_user'>关于我们</div>
						<div className='line'></div>
						<div className='icon_list'>
							<img
								className='icon_list_img'
								src={require('../../assets/images/icon_two.png')}
								alt=''
							/>
							<img
								className='icon_list_img'
								src={require('../../assets/images/icon_one.png')}
								alt=''
							/>
							<img
								className='icon_list_img'
								src={require('../../assets/images/icon_three.png')}
								alt=''
							/>
							<img
								className='icon_list_img'
								src={require('../../assets/images/icon_four.png')}
								alt=''
							/>
						</div>
						<div className='footer_nav'>
							<div className='footer_nav_item'>关注我们</div>
							<div className='footer_nav_item'>业务</div>
							<div className='footer_nav_item'>公告</div>
							<div className='footer_nav_item'>诚聘英才</div>
						</div>
						<div className='footer_des'>
							<a
								target='_blank'
								rel='noopener noreferrer'
								href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12011502000810'
								style={{
									display: 'flex',
									justifyContent: 'center'
								}}
							>
								<img
									style={{
										width: '15px',
										height: '15px'
									}}
									src={require('../../assets/images/icon_record.png')}
									alt=''
								/>
								<p
									style={{
										marginLeft: '5px'
									}}
								>
									津公网安备 12011502000810号
								</p>
							</a>
							Copyright 2021-2023 天津畅游天下科技有限公司 企业
							<a
								style={{ marginLeft: '5px' }}
								href='https://beian.miit.gov.cn/'
								target='_blank'
							>
								津ICP备2020009338号-1
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
