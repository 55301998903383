import React, { Component } from 'react'
import './index.css'
import 'antd/dist/antd.css'
import intl from 'react-intl-universal'
//引入action
import { exchangeToEn, exchangeToZh } from '../../redux/actions/langes'
//引入connect用于连接UI组件与redux
import { connect } from 'react-redux'
import { emit } from '../../locales/emit'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import QRCode from 'qrcode-react'

import { isWeixin } from '../../utils/utils'

class Home extends Component {
	state = {
		isShowLayer: false,
		collapsed: false, // 媒体查询--  pc:false moble: true
		isZH_CN: true,
		zoologyIndex: 'one',
		contentStyle: {
			width: '100%' // 1920px改成100%
			// height: '841px'
		},
		// 轮播图配置参数
		lunboSetting: {
			dots: true,
			lazyLoad: true,
			autoplay: true
		},
		// 轮播图图片地址
		bannerList: ['banner-one.png', 'banner-two.png'],
		isShowNav: false,
		navList: [
			{ name: '首页', id: 0 },
			{ name: '业务', id: 1 },
			{ name: '关于我们', id: 4 }
		],
		navActive: 0,

		appDownLoadUrl: ''
	}
	// 判断当前视窗是否大于760px
	handleClientW = width => {
		if (width < 760) {
			this.setState({ collapsed: true })
		} else {
			this.setState({ collapsed: false })
			window.addEventListener('scroll', function() {
				var header = document.querySelector('header')
				header.classList.toggle('sticky', window.scrollY > 0)
			})
		}
	}
	fetchAppDownloadUrl() {
		const data = {
			appType: 'ANDROID'
		}

		fetch('https://mc-mall-app.michong.org.cn/v1/appVersion/show/appType', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: new Headers({
				'Content-Type': 'application/json'
			})
		})
			.then(response => response.json())
			.then(data => {
				this.setState({ appDownLoadUrl: data.data.downPath })
			})
		// this.setState({
		// 	appDownLoadUrl:
		// 		"https://mc-mall-download.michong.net.cn/mc/version/android/V20/V20_02/michong.apk",
		// });
	}
	componentDidMount() {
		this.fetchAppDownloadUrl()
		window.addEventListener('resize', this.handleResize.bind(this)) //监听窗口大小改变
		let clientW = document.documentElement.clientWidth
		this.handleClientW(clientW)
		new Swiper('.swiper-container', {
			loop: true, // 循环模式选项
			// 如果需要分页器
			pagination: {
				el: '.swiper-pagination'
			}
		})
	}
	handleResize = e => {
		let e_width = e.target.innerWidth
		this.handleClientW(e_width)
		console.log('浏览器窗口大小改变事件', e.target.innerWidth)
	}
	// 生态圈模块鼠标停留事件
	onMouseOverZoology(index) {
		this.setState({ zoologyIndex: index })
	}
	// 切换英文
	exChangeToE = () => {
		this.props.exchangeToEn('en-US')
		// 发送消息
		emit.emit('change_language', 'en-US')
	}
	// 切换中文
	exChangeToC = () => {
		this.props.exchangeToZh('zh-CN')
		// 发送消息
		emit.emit('change_language', 'zh-CN')
	}
	scrollToAnchor = anchorName => {
		if (anchorName) {
			// 找到锚点
			let anchorElement = document.getElementById(anchorName)
			// 如果对应id的锚点存在，就跳转到锚点
			if (anchorElement) {
				anchorElement.scrollIntoView({
					block: 'start',
					behavior: 'smooth'
				})
			}
		}
	}
	appDownloadDialog = () => {
		if (isWeixin())
			return this.setState({
				isShowLayer: true
			})
		window.location.href = this.state.appDownLoadUrl
	}
	showNavList = () => {
		this.setState({ isShowNav: !this.state.isShowNav })
	}
	sleectNav = index => {
		this.setState({ navActive: index })
		switch (index) {
			case 0:
				//语句
				this.props.history.push(`/`)
				break
			case 1:
				this.props.history.push(`/business`)
				//语句
				break
			case 2:
				this.props.history.push(`/user`)
				//语句
				break
			default:
				//语句
				this.props.history.push(`/`)
		}
	}
	render() {
		return (
			<div>
				{!this.state.collapsed ? (
					<div
						className={
							this.props.langes === 'zh-CN' ? '' : 'font_X'
						}
					>
						<header>
							<div className='logo'></div>
							{/* 中文时使用思源黑体 */}
							<div
								className={
									this.props.langes === 'zh-CN'
										? 'header_nav_s'
										: 'header_nav'
								}
							>
								<div className='topnav'>
									<div className='topnav_itm'>
										<div
											className='mainnav'
											onClick={() =>
												this.scrollToAnchor('aboutUs')
											}
										>
											{intl.get('aboutUs')}
										</div>
										{/* <div className='subnav'>
                                    <div className='subnav_itm'>{intl.get('companyIntroduction')}</div>
                                    <div className='subnav_itm'>{intl.get('managementIdea')}</div>
                                    <div className='subnav_itm'>{intl.get('developmentHistory')}</div>
                                    <div className='subnav_itm'>{intl.get('corporateBrand')}</div>
                                    <div className='subnav_itm'>{intl.get('socialResponsibility')}</div>
                                </div> */}
									</div>
									<div className='topnav_itm'>
										<div
											className='mainnav'
											onClick={() =>
												this.scrollToAnchor('business')
											}
										>
											{intl.get('business')}
										</div>
										{/* <div className='subnav'>
                                    <div className='subnav_itm'>{intl.get('core')}</div>
                                    <div className='subnav_itm'>{intl.get('talent')}</div>
                                </div> */}
									</div>
									<div className='topnav_itm'>
										<div
											className='mainnav'
											onClick={() =>
												this.scrollToAnchor(
													'announcement'
												)
											}
										>
											{intl.get('announcement')}
										</div>
										{/* <div className='subnav'>
                                    <div className='subnav_itm'>{intl.get('CorporateAnnouncements')}</div>
                                    <div className='subnav_itm'>{intl.get('repository')}</div>
                                </div> */}
									</div>
									<div className='topnav_itm'>
										<div className='mainnav'>
											{intl.get('talentedPeople')}
										</div>
										{/* <div className='subnav'>
                                    <div className='subnav_itm'>{intl.get('recruitment')}</div>
                                </div> */}
									</div>
									<div className='topnav_itm'>
										<div
											className='mainnav'
											onClick={() =>
												this.appDownloadDialog()
											}
										>
											{intl.get('appDownload')}
										</div>
										<div className='dialog'>
											<QRCode
												size={200}
												value={
													this.state.appDownLoadUrl
												}
												logo={require('../../assets/images/m_image_logo.png')}
												logoWidth={50}
												logoHeight={50}
											></QRCode>
										</div>
									</div>
								</div>
								<div className='language'>
									<span
										onClick={this.exChangeToC}
										className={
											this.props.langes === 'zh-CN'
												? 'langIsActive'
												: ''
										}
									>
										中{' '}
									</span>
									<span>/</span>
									<span
										onClick={this.exChangeToE}
										className={
											this.props.langes === 'en-US'
												? 'langIsActive'
												: ''
										}
									>
										{' '}
										ENGLISH
									</span>
								</div>
							</div>
						</header>
						{/* banner图 */}
						<div className='swiper-container'>
							<div className='swiper-wrapper'>
								{/* 动态遍历banner图片地址 */}
								{this.state.bannerList.map((item, index) => {
									return (
										<div
											className='swiper-slide'
											key={index}
										>
											<img
												style={this.state.contentStyle}
												src={require('../../assets/images/' +
													item)}
												alt=''
											/>
										</div>
									)
								})}
							</div>
							{/* 分页标识 */}
							<div className='swiper-pagination'></div>
						</div>
						{/* 最新资讯 */}
						{/* 中文时使用思源黑体 */}
						<div
							className={
								this.props.langes === 'zh-CN'
									? 'information_s'
									: 'information'
							}
						>
							<img
								className='information_text'
								src={require(this.props.langes === 'zh-CN'
									? '../../assets/images/information.png'
									: '../../assets/images/information_E.png')}
								alt=''
							/>
							<div id='announcement' className='information_top'>
								<div className='information_left'>
									<img
										src={require('../../assets/images/information_one.png')}
										alt=''
									/>
								</div>
								<div className='information_right'>
									<img
										src={require('../../assets/images/information_three.png')}
										alt=''
									/>
									<div className='information_right_t'>
										<div className='information_right_t_itm'>
											{intl.get('control')}
										</div>
										<div className='information_right_t_itm'>
											{intl.get('socialResponsibility')}
										</div>
									</div>
								</div>
							</div>
							<div className='information_center'>
								<div className='information_center_tit'>
									{intl.get('announcement')}
								</div>
							</div>
							{/* 中文时使用优设黑标 */}
							<div
								className={
									this.props.langes === 'zh-CN'
										? 'information_bottom_y'
										: 'information_bottom'
								}
							>
								<div className='information_bottom_left'>
									<div className='information_bottom_text'>
										{intl.get('pressCenter')}
									</div>
									<div className='bgc'></div>
								</div>
								<div className='information_bottom_right'>
									<div className='information_bottom_text'>
										{intl.get('midianyuan')}
									</div>
									<div className='bgc'></div>
								</div>
							</div>
						</div>
						{/* 生态圈 */}
						{/* 中文时使用优设黑标 */}
						<div
							id='business'
							className={
								this.props.langes === 'zh-CN'
									? 'zoology_y'
									: 'zoology'
							}
						>
							<div className='zoology_classify'>
								<div
									className={
										this.state.zoologyIndex === 'one'
											? 'classify_item_default'
											: 'classify_item'
									}
									onMouseOver={() => {
										this.onMouseOverZoology('one')
									}}
								>
									<div
										className={
											this.state.zoologyIndex === 'one'
												? 'annulus_default'
												: 'annulus'
										}
									></div>
									<div className='classify_item_text'>
										{intl.get('virtual')}
									</div>
								</div>
								<div
									className={
										this.state.zoologyIndex === 'two'
											? 'classify_item_default'
											: 'classify_item'
									}
									onMouseOver={() => {
										this.onMouseOverZoology('two')
									}}
								>
									<div
										className={
											this.state.zoologyIndex === 'two'
												? 'annulus_default'
												: 'annulus'
										}
									></div>
									<div className='classify_item_text'>
										{intl.get('digitalScene')}
									</div>
								</div>
								<div
									className={
										this.state.zoologyIndex === 'three'
											? 'classify_item_default'
											: 'classify_item'
									}
									onMouseOver={() => {
										this.onMouseOverZoology('three')
									}}
								>
									<div
										className={
											this.state.zoologyIndex === 'three'
												? 'annulus_default'
												: 'annulus'
										}
									></div>
									<div className='classify_item_text'>
										{intl.get('metaUniverse')}
									</div>
								</div>
							</div>
							<div
								className={
									this.state.zoologyIndex === 'one'
										? 'zoology_img_black'
										: 'zoology_img'
								}
							>
								<img
									src={require('../../assets/images/zoology_one.png')}
									alt=''
								/>
								<div className='zoology_img_tit'>
									<div className='text'>
										{intl.get('Gamification')}
									</div>
									<div className='bgc'></div>
								</div>
							</div>
							<div
								className={
									this.state.zoologyIndex === 'two'
										? 'zoology_img_black'
										: 'zoology_img'
								}
							>
								<img
									src={require('../../assets/images/zoology_two.png')}
									alt=''
								/>
								<div className='zoology_img_tit'>
									<div className='text'>
										{intl.get('SocialInteraction')}
									</div>
									<div className='bgc'></div>
								</div>
							</div>
							<div
								className={
									this.state.zoologyIndex === 'three'
										? 'zoology_img_black'
										: 'zoology_img'
								}
							>
								<img
									src={require('../../assets/images/zoology_three.png')}
									alt=''
								/>
								<div className='zoology_img_tit'>
									<div className='text'>
										{intl.get('economicSystem')}
									</div>
									<div className='bgc'></div>
								</div>
							</div>
						</div>

						{/* 中文时使用思源黑体 */}
						<footer
							id='aboutUs'
							className={
								this.props.langes === 'zh-CN'
									? 'footer_s'
									: 'footer'
							}
						>
							<div className='footer_tit'>
								{intl.get('aboutUs')}
							</div>
							<div className='footer_icon'>
								<div className='footer_icon_item'>
									<img
										className='footer_icon_item_img'
										src={require('../../assets/images/icon_one.png')}
										alt=''
									/>
									{/* <img className='footer_icon_item_qrcode' src={require('../../assets/images/wxQrcode.jpg')}></img> */}
								</div>
								<div className='footer_icon_item'>
									<img
										className='footer_icon_item_img'
										src={require('../../assets/images/icon_two.png')}
										alt=''
									/>
									{/* <img className='footer_icon_item_qrcode' src={require('../../assets/images/wxQrcode.jpg')}></img> */}
								</div>
								<div className='footer_icon_item'>
									<img
										className='footer_icon_item_img'
										src={require('../../assets/images/icon_three.png')}
										alt=''
									/>
									{/* <img className='footer_icon_item_qrcode' src={require('../../assets/images/wxQrcode.jpg')}></img> */}
								</div>
								<div className='footer_icon_item'>
									<img
										className='footer_icon_item_img'
										src={require('../../assets/images/icon_four.png')}
										alt=''
									/>
									{/* <img
										className='footer_icon_item_qrcode'
										src={require('../../assets/images/wxQrcode.jpg')}
									></img> */}
								</div>
							</div>
							<div className='footer_nav'>
								<div className='footer_nav_items'>
									{intl.get('attention')}
								</div>
								<div className='footer_nav_items'>
									{intl.get('business')}
								</div>
								<div className='footer_nav_items'>
									{intl.get('announcement')}
								</div>
								<div className='footer_nav_items'>
									{intl.get('talentedPeople')}
								</div>
							</div>
							<div className='copyright'>
								<a
									className='records'
									target='_blank'
									rel='noopener noreferrer'
									href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12011502000810'
								>
									<img
										style={{
											width: '20px',
											height: '20px',
											marginTop: '3px'
										}}
										src={require('../../assets/images/icon_record.png')}
										alt=''
									/>
									<p
										style={{
											marginLeft: '5px'
										}}
									>
										津公网安备 12011502000810号
									</p>
								</a>
								<p
									style={{
										float: 'left',
										marginLeft: '5px'
									}}
								>
									Copyright 2021-2023 天津畅游天下科技有限公司
									企业
									<a
										style={{ marginLeft: '5px' }}
										href='https://beian.miit.gov.cn/'
										target='_blank'
									>
										津ICP备2020009338号-1
									</a>
								</p>
							</div>
						</footer>
					</div>
				) : null}
				{this.state.collapsed ? (
					<div className='m_box'>
						<div className='m_head'>
							<img
								className='m_logo'
								src={require('../../assets/images/m_icon_logo.png')}
								alt=''
							/>
							<img
								className='m_nav_icon'
								src={require('../../assets/images/icon_gengduo.png')}
								onClick={() => this.showNavList()}
								alt=''
							/>
						</div>
						{/* 下拉菜单 */}
						{this.state.isShowNav ? (
							<div className='m_nav'>
								{this.state.navList.map((item, index) => {
									return (
										<div
											className={
												this.state.navActive === item.id
													? 'm_nav_item_active'
													: 'm_nav_item'
											}
											key={index}
											onClick={() =>
												this.sleectNav(index)
											}
										>
											{item.name}
										</div>
									)
								})}
							</div>
						) : null}
						<div className='m_main_h'>
							<div className='m_mc_tit'>
								<div>科技无止境 无处不创新</div>
								<div>革新时代 创新而生</div>
							</div>
							{/* <div className='m_upload'>
								<img
									className='m_upload_logo'
									src={require('../../assets/images/m_image_logo.png')}
								/>
								<div className='m_upload_btn'>
									<div
										className='m_upload_text'
										onClick={() => this.appDownloadDialog()}
									>
										立即下载
									</div>
									<div className='m_upload_icon'></div>
								</div>
							</div> */}
						</div>
						{this.state.isShowLayer && (
							<div className='layer'>
								<img
									className='image_guide'
									src={require('../../assets/images/image_guide.png')}
									alt=''
								/>
							</div>
						)}
					</div>
				) : null}
			</div>
		)
	}
}
//使用connect()()创建并暴露一个Count的容器组件
export default connect(
	state => ({
		langes: state.langes
	}),
	{ exchangeToEn, exchangeToZh }
)(Home)
