import Home from '../pages/Home'
import Business from '../pages/Business'
import User from '../pages/User'

let routes = [
	{
		path: '/',
		component: Home,
		exact: true
	},
	{
		path: '/business',
		component: Business
		// routes: [   /*嵌套路由*/
		//   {
		//     path: "/business",
		//     component: NewsOne
		//   },
		//   {
		//     path: "/News/NewsTwo",
		//     component: NewsTwo
		//   }
		// ]
	},
	{
		path: '/user',
		component: User
	}
]

export default routes
